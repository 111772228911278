type AccessParams = {
  isHr: boolean
  isSuperHr: boolean
  isTechAdmin: boolean
  isUser: boolean
  route: string
}

export function determineAccess({
  isHr,
  isSuperHr,
  isTechAdmin,
  isUser,
  route,
}: AccessParams) {
  if (isHr || isSuperHr || isTechAdmin) {
    return { authorized: true, redirectTo: null }
  }

  const pageType = (() => {
    if (route.toLocaleLowerCase().startsWith("/public")) {
      return "public"
    }

    if (route === "" || route === "/") {
      return "empty"
    }

    if (route.toLocaleLowerCase().startsWith("/certificates")) {
      return "certificates"
    }

    return "private"
  })()

  switch (pageType) {
    case "public":
      return { authorized: isUser, redirectTo: null }
    case "empty":
      if (isUser) {
        return { authorized: true, redirectTo: "/certificates" }
      }

      return { authorized: false, redirectTo: "/public/login" }
    case "certificates":
      if (isUser) {
        return { authorized: true, redirectTo: null }
      }

      return { authorized: false, redirectTo: "/public/login" }
    case "private":
      if (isUser) {
        return { authorized: true, redirectTo: "/certificates" }
      }

      return { authorized: false, redirectTo: "/public/login" }
    default:
      return { authorized: false, redirectTo: "/public/login" }
  }
}
