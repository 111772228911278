import { useEffect, useState } from "react"

const setCookie = (name: string, value: string, days: number, path: string) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString()
  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${expires}; path=${path};`
}

const getCookie = (name: string) => {
  if (typeof window === "undefined") {
    return undefined
  }

  const cookies = document.cookie.split("; ")
  for (const cookie of cookies) {
    const [cookieName, ...cookieValueParts] = cookie.split("=")
    if (cookieName === name) {
      return decodeURIComponent(cookieValueParts.join("="))
    }
  }
  return undefined
}

const useCookie = (
  cookieName: string,
  initialValue = ""
): {
  cookieValue: string | undefined
  updateCookie: (value: string, days: number, path?: string) => void
  deleteCookie: (path: string) => void
} => {
  const [cookieValue, setCookieValue] = useState(() => {
    if (cookieName !== "" && initialValue) {
      const cookie = getCookie(cookieName)
      if (cookie !== "") return cookie
      return initialValue
    }
  })

  useEffect(() => {
    if (initialValue) {
      return
    }

    console.log("useCookie-debug: cookieName", cookieName)
    console.log("useCookie-debug: getCookie(cookieName)", getCookie(cookieName))
    // useCookie-debug: cookieName SKRIBA_LOGIN_WITH_CLERK
    // useCookie-debug: getCookie(cookieName) SKRIBA_LOGIN_WITH_CLERK=false
    setCookieValue(getCookie(cookieName))
  }, [initialValue, cookieName])

  const updateCookie = (value: string, days = 365, path?: string): void => {
    setCookieValue(value)
    setCookie(cookieName, value, days, path ?? "/")
  }

  const deleteCookie = (path: string): void => {
    updateCookie("", -1, path)
    setCookieValue("")
  }

  return { cookieValue, updateCookie, deleteCookie }
}

export default useCookie
